import dynamic from "next/dynamic"
import Link from 'next/link'
const PageProvider = dynamic(() => import("../services/Global"))
const HeadPage = dynamic(() => import("../components/pageComponent/Head"))
import {Text} from "../services/providers/Language"

import path from "../services/contents/paths"

import logo from 'img/general/logo/logo_clowns_dans_la_sciure_show_spectacles_animations.png'

function NotFound() {
    return (
        <PageProvider>
            <HeadPage title={<Text id={'title404'}/>} description={<Text id={''}/>} posterImage={logo}/>

            <div className='uk-align-center uk-margin-large-bottom uk-margin-large-top uk-text-center'>
                <h1><Text id={'message404'}/></h1>

                <Link href={path.home} className='uk-link uk-link-text text-hover-gold'>
                    <Text id={'redirect404'}/>
                </Link>
            </div>
        </PageProvider>
    )
}

export default NotFound